"use strict";

// tohle nahraje i soubory, ktere nejsou primo linkovane z entrypointu
// import "./images/*.{png,jpg,jpeg,gif,svg}";

// UIkit
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

// loads the Icon plugin
UIkit.use(Icons);

// import stylu
import "./../sass/index.scss";

// tiny slider
import { tns } from "tiny-slider/src/tiny-slider";

// noUiSlider
import noUiSlider from "nouislider";
import wNumb from "wnumb";

// <div id="slider"></div>
var sliderPress = document.getElementById("sliderPress");
if (sliderPress) {
	//console.log("filterPressMin = " + filterPressMin);

	noUiSlider.create(sliderPress, {
		start: [filterPressMinA, filterPressMaxA],
		connect: true,
		tooltips: [wNumb({ decimals: 0 }), wNumb({ decimals: 0 })],
		step: 1,
		range: {
			min: filterPressMin,
			max: filterPressMax,
		},
	});
}

var sliderCapacity = document.getElementById("sliderCapacity");
if (sliderCapacity) {
	noUiSlider.create(sliderCapacity, {
		start: [filterCapacityMinA, filterCapacityMaxA],
		connect: true,
		tooltips: [wNumb({ decimals: 0 }), wNumb({ decimals: 0 })],
		step: 1,
		range: {
			min: filterCapacityMin,
			max: filterCapacityMax,
		},
	});
}

var sliderVacuum = document.getElementById("sliderVacuum");
if (sliderVacuum) {
	noUiSlider.create(sliderVacuum, {
		start: [filterVacuumMinA, filterVacuumMaxA],
		connect: true,
		tooltips: [wNumb({ decimals: 0 }), wNumb({ decimals: 0 })],
		step: 1,
		range: {
			min: filterVacuumMin,
			max: filterVacuumMax,
		},
	});
}

var filtrSend = document.getElementById("filtrSend");

if (filtrSend) {
	filtrSend.onclick = function () {
		var link = LINK;
		var fCapacity = sliderCapacity.noUiSlider.get();
		var fPress = sliderPress.noUiSlider.get();
		var fVacuum = sliderVacuum.noUiSlider.get();

		link =
			link +
			"?filterCapacityMin=" +
			parseInt(fCapacity[0]) +
			"&filterCapacityMax=" +
			parseInt(fCapacity[1]) +
			"&filterPressMin=" +
			parseInt(fPress[0]) +
			"&filterPressMax=" +
			parseInt(fPress[1]) +
			"&filterVacuumMin=" +
			parseInt(fVacuum[0]) +
			"&filterVacuumMax=" +
			parseInt(fVacuum[1]);
		window.location.href = link;
		//console.log("LINK = " + link);
	};
}
//menu
//MENU OPEN
var opener = document.getElementById("ccollapseToggle");
if (opener) {
	opener.onclick = function () {
		menuManipulate();
	};

	var submenuCloserButton = document.getElementsByClassName("submenuS");

	for (var i = 0; i < submenuCloserButton.length; i++) {
		submenuCloserButton[i].addEventListener("click", menuClose, false);
	}
}

/*
try {
	var opener = document.getElementById("ccollapseToggle");

	opener.onclick = function () {
		menuManipulate();
	};
} catch (error) {
	console.error(error);
	// expected output: ReferenceError: nonExistentFunction is not defined
	// Note - error messages will vary depending on browser
}
*/

function menuManipulate() {
	const openQ = document.querySelector("#ccollapse");
	if (openQ.classList.contains("open")) {
		//console.log("otevřeno");
		openQ.classList.remove("open");
	} else {
		//console.log("zavřeno");
		openQ.classList.add("open");
	}
}

function menuClose() {
	const openQ = document.querySelector("#ccollapse");
	openQ.classList.remove("open");
}
